import React from 'react'
import Navbar from '../inc/Navbar'
import Tilespage from './Tilespage'

function TilesProducts() {
  return (
    <div>
        
      <Navbar/>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <Tilespage/>
      <br/>
    </div>
  )
}

export default TilesProducts