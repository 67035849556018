import React from 'react'
import Navbar from '../inc/Navbar'
import Paverall from './Paverall'

function Paverpage() {
  return (
    <div>
        <Navbar/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <Paverall/>
        <br/>
    </div>
  )
}

export default Paverpage