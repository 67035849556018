import React from 'react'
import Navbar from '../inc/Navbar';
import Slider from "../inc/Slider";
import Pavers from "../images/pavers.jpg"
import Tiles from "../images/tiles.jpg"
import Brick from "../images/brick.jpg"
import Others from "../images/others.jpg"
import ReactPlayer from "react-player";
import Slide from "../pages/Slide";
import Founder from "../images/founder.jpg";
import Blockpage from "../images/block120page.jpg";
import otherpage from "../images/otherpages.jpg";

import "./Main.css";

import { Link } from "react-router-dom";
import Map from "../pages/Map";
import ReactWhatsapp from 'react-whatsapp';
import ScrollToTop from "react-scroll-to-top";
import { Typewriter } from 'react-simple-typewriter'

import Wallrupaeco from "../images/wallrupaeco.jpg"
import Walltile from "../images/walltile.jpg"
import Walltilesrupa from "../images/walltilesrupa.jpg"
import Walltiles from "../images/walltiles.jpg"
import Wallecobrick from "../images/wallecobrick.jpg"

import Breezeblock from "../images/BreezeBlock.jpg"
import Blockrupaeco from "../images/blockrupaeco.jpg"
import Breezeblockrupa from "../images/breezeblockrupa.jpg"
import Blockrupaecobrick from "../images/blockrupaecobrick.jpg"
import Blockrupa2 from "../images/blockrupa2.jpg"
import Blockrupaeco1 from "../images/blockrupaeco1.jpg"

import KERBEWSTONErUPAECOBRICK from "../images/KERBEWSTONE.jpg"
import RupaEcoBrick from "../images/KERBESTONERUPAECOBRICK.jpg"
import KERBEstone from "../images/KERBE.jpg"
import KERBEStone from "../images/KERBES.jpg"

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import {
  FacebookShareButton, WhatsappShareButton, WhatsappIcon,
  FacebookIcon, TwitterShareButton, EmailShareButton, EmailIcon,

  LinkedinShareButton,
  LinkedinIcon,
  TwitterIcon,
} from "react-share";
import Paverall from './Paverall';
import Tilespage from './Tilespage';


function Main() {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  return (
    <div>
      <Navbar />
      <br />
      <br />
      <br />
      <br />

      <div className="album py-0">
        <div className="container">

          <div className="row">
            <h5>Rupa Eco Brick</h5>
          </div>
        </div>
      </div>
      <ScrollToTop smooth top="100" color="dark" />
      <Slider />

      <div style={{ color: "Green", margin: "6px" }} className="fixed-bottom "> <ReactWhatsapp number="+91 9434102089" className="buttonapply " message="I am Interested"> <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-whatsapp" viewBox="0 0 16 16">
        <path d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z" />
      </svg> WhatsApp  <div className="spinner-grow text-light spinner-grow-sm" role="status">
          <span className="visually-hidden"> Loading...</span>
        </div></ReactWhatsapp></div>


      <div className="p-1 mb-0 bg-dark text-white">

        <div className="album py-0">

          <div className="container">

            <div className="row">
              <div className="col-md-7">

                <div className="card-body">

                  <h1 className="card-text mb-2" style={{ paddingTop: "1rem", margin: "auto 0", color: "Gray" }}> <b> OUR PRODUCT <span style={{ color: "white", fontWeight: "bold", marginleft: '7px' }}>
                    <Typewriter
                      loop
                      cursor
                      cursorStyle="_"
                      typeSpeed={90}
                      deleteSpeed={50}
                      delaySpeed={1000}
                      words={[" PAVER ", " TILES", "BRICK"]}
                    />
                  </span>
                  </b></h1>

                  <p className="card-text mb-3">Rupa Eco Brick was founded/established in 2009</p>

                </div>


              </div>
            </div>
          </div>
        </div>

      </div>

      <div className="album py-2">
        <div className="container">

          <div className="row">

            <div className="col-md-6" >

              <div className="d-flex justify-content-between align-items-center">
                <div className="card mb-4 me-3 border-dark box">

                  <Link to="/paver">
                    <img src={Pavers} className="card-img-top" alt="Pavers" /></Link>


                  <div className="card-body">
                    <div className="d-flex justify-content-between align-items-center">
                      <p style={{ color: "DarkSlateBlue		" }} className="card-text mb-2"><b>Paver</b></p>
                      <Link to="/">

                      </Link>

                      {/* <!-- Button trigger modal --> */}
                      <button type="button" className="btn btn-dark btn-sm mb-2" data-bs-toggle="modal" data-bs-target="#exampleModal">
                        Share <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" className="bi bi-share-fill" viewBox="0 0 16 16">
                          <path d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5z" />
                        </svg>
                      </button>

                      {/* <!-- Modal --> */}
                      <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h5 className="modal-title" id="exampleModalLabel">Share Now </h5>
                              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                              < FacebookShareButton url="/https://www.rupaecobrick.com/paverall" className="me-2" quote={"Rupa Eco Brick"} hastag={"#WebApp"}
                              >
                                <FacebookIcon size={37} round={true} />
                              </FacebookShareButton>

                              <  WhatsappShareButton url="/https://www.rupaecobrick.com/paverall" className="me-2" quote={"Rupa Eco Brick"} hastag={"#WebApp"}
                              >
                                <WhatsappIcon size={37} round={true} />
                              </ WhatsappShareButton>

                              <  TwitterShareButton url="/https://www.rupaecobrick.com/paverall" className="me-2" quote={"Rupa Eco Brick"} hastag={"#WebApp"}
                              >
                                <TwitterIcon size={37} round={true} />
                              </ TwitterShareButton>

                              <  LinkedinShareButton url="/https://www.rupaecobrick.com/paverall" className="me-2" quote={"Rupa Eco Brick"} hastag={"#WebApp"}
                              >

                                <  LinkedinIcon size={37} round={true} />
                              </ LinkedinShareButton>

                              <  EmailShareButton url="/https://www.rupaecobrick.com/paverall" quote={"Rupa Eco Brick"} hastag={"#WebApp"}
                              >
                                <  EmailIcon size={37} round={true} />
                              </ EmailShareButton>
                            </div>
                            <div class="modal-footer">
                              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <center>
                    <Link to="/paver">
                      <center><button type="button" className="btn btn-outline-dark mb-2"> <small> Click Here </small></button>

                      </center>
                    </Link>
                    <h5 style={{ color: "Gray" }} className="card-title">Rupa Eco Brick</h5>

                  </center>
                </div>


                <div className="card mb-4 border-dark box shadow">
                  <Link to="/tiles">
                    <img src={Tiles} className="card-img-top" alt="Tiles" /></Link>
                  <div class="card-body">
                    <div className="d-flex justify-content-between align-items-center">
                      <p style={{ color: "DarkSlateBlue	 " }} className="card-text mb-2"><b>Tiles</b></p>
                      <Link to="/">

                      </Link>

                      {/* <!-- Button trigger modal --> */}
                      <button type="button" className="btn btn-dark btn-sm mb-2" data-bs-toggle="modal" data-bs-target="#exampleModal">
                        Share <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" className="bi bi-share-fill" viewBox="0 0 16 16">
                          <path d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5z" />
                        </svg>
                      </button>

                      {/* <!-- Modal --> */}
                      <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h5 className="modal-title" id="exampleModalLabel">Share Now </h5>
                              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                              < FacebookShareButton url="/https://www.rupaecobrick.com/tiles" className="me-2" quote={"Rupa Eco Brick"} hastag={"#WebApp"}
                              >
                                <FacebookIcon size={37} round={true} />
                              </FacebookShareButton>

                              <  WhatsappShareButton url="/https://www.rupaecobrick.com/tiles" className="me-2" quote={"Rupa Eco Brick"} hastag={"#WebApp"}
                              >
                                <WhatsappIcon size={37} round={true} />
                              </ WhatsappShareButton>

                              <  TwitterShareButton url="/https://www.rupaecobrick.com/tiles" className="me-2" quote={"Rupa Eco Brick"} hastag={"#WebApp"}
                              >
                                <TwitterIcon size={37} round={true} />
                              </ TwitterShareButton>

                              <  LinkedinShareButton url="/https://www.rupaecobrick.com/tiles" className="me-2" quote={"Rupa Eco Brick"} hastag={"#WebApp"}
                              >

                                <  LinkedinIcon size={37} round={true} />
                              </ LinkedinShareButton>

                              <  EmailShareButton url="/https://www.rupaecobrick.com/tiles" quote={"Rupa Eco Brick"} hastag={"#WebApp"}
                              >
                                <  EmailIcon size={37} round={true} />
                              </ EmailShareButton>
                            </div>
                            <div class="modal-footer">
                              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Link to="/tiles">

                    <center><button type="button" className="btn btn-outline-dark mb-2"> <small> Click Here </small></button>
                    </center>

                    <center>
                      <h5 style={{ color: "Gray" }} className="card-title">Rupa Eco Brick</h5>
                    </center>
                  </Link>
                </div>


              </div>

              <div className="d-flex justify-content-between align-items-center">
                <div className="card mb-4 me-3 border-dark box shadow">
                  <Link to="/brickpage">
                    <img src={Brick} className="card-img-top" alt="Brick" /></Link>
                  <div class="card-body">
                    <div className="d-flex justify-content-between align-items-center">
                      <p style={{ color: "DarkSlateBlue		" }} className="card-text mb-2"><b>Brick</b></p>
                      <Link to="/">

                      </Link>

                      {/* <!-- Button trigger modal --> */}
                      <button type="button" className="btn btn-dark btn-sm mb-2" data-bs-toggle="modal" data-bs-target="#exampleModal">
                        Share <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" className="bi bi-share-fill" viewBox="0 0 16 16">
                          <path d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5z" />
                        </svg>
                      </button>

                      {/* <!-- Modal --> */}
                      <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h5 className="modal-title" id="exampleModalLabel">Share Now </h5>
                              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                              < FacebookShareButton url="/https://www.rupaecobrick.com/brick" className="me-2" quote={"Rupa Eco Brick"} hastag={"#WebApp"}
                              >
                                <FacebookIcon size={37} round={true} />
                              </FacebookShareButton>

                              <  WhatsappShareButton url="/https://www.rupaecobrick.com/brick" className="me-2" quote={"Rupa Eco Brick"} hastag={"#WebApp"}
                              >
                                <WhatsappIcon size={37} round={true} />
                              </ WhatsappShareButton>

                              <  TwitterShareButton url="/https://www.rupaecobrick.com/brick" className="me-2" quote={"Rupa Eco Brick"} hastag={"#WebApp"}
                              >
                                <TwitterIcon size={37} round={true} />
                              </ TwitterShareButton>

                              <  LinkedinShareButton url="/https://www.rupaecobrick.com/brick" className="me-2" quote={"Rupa Eco Brick"} hastag={"#WebApp"}
                              >

                                <  LinkedinIcon size={37} round={true} />
                              </ LinkedinShareButton>

                              <  EmailShareButton url="/https://www.rupaecobrick.com/brick" quote={"Rupa Eco Brick"} hastag={"#WebApp"}
                              >
                                <  EmailIcon size={37} round={true} />
                              </ EmailShareButton>
                            </div>
                            <div className="modal-footer">
                              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Link to="/brickpage">


                    <center>

                      <button type="button" className="btn btn-outline-dark mb-2"> <small> Click Here </small></button>

                    </center>
                    <center>
                      <h5 style={{ color: "Gray" }} className="card-title">Rupa Eco Brick</h5>
                    </center>
                  </Link>
                </div>


                <div className="card mb-4 me-0 border-dark box shadow">

                  <Link to="/benchpage" target="_blank" rel="noopener noreferrer">
                    <img src={Others} className="card-img-top" alt="Others" />
                  </Link>
                  <div className="card-body">
                    <div className="d-flex justify-content-between align-items-center">
                      <p style={{ color: "DarkSlateBlue		" }} className="card-text mb-2"><b>Garden Bench</b></p>
                      <Link to="/">

                      </Link>

                      {/* <!-- Button trigger modal --> */}
                      <button type="button" className="btn btn-dark btn-sm mb-2" data-bs-toggle="modal" data-bs-target="#exampleModal">
                        Share <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" className="bi bi-share-fill" viewBox="0 0 16 16">
                          <path d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5z" />
                        </svg>
                      </button>

                      {/* <!-- Modal --> */}
                      <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h5 className="modal-title" id="exampleModalLabel">Share Now </h5>
                              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                              < FacebookShareButton url="/https://www.rupaecobrick.com/gardenbench" className="me-2" quote={"Rupa Eco Brick"} hastag={"#WebApp"}
                              >
                                <FacebookIcon size={37} round={true} />
                              </FacebookShareButton>

                              <  WhatsappShareButton url="/https://www.rupaecobrick.com/gardenbench" className="me-2" quote={"Rupa Eco Brick"} hastag={"#WebApp"}
                              >
                                <WhatsappIcon size={37} round={true} />
                              </ WhatsappShareButton>

                              <  TwitterShareButton url="/https://www.rupaecobrick.com/gardenbench" className="me-2" quote={"Rupa Eco Brick"} hastag={"#WebApp"}
                              >
                                <TwitterIcon size={37} round={true} />
                              </ TwitterShareButton>

                              <  LinkedinShareButton url="/https://www.rupaecobrick.com/gardenbench" className="me-2" quote={"Rupa Eco Brick"} hastag={"#WebApp"}
                              >

                                <  LinkedinIcon size={37} round={true} />
                              </ LinkedinShareButton>

                              <  EmailShareButton url="/https://www.rupaecobrick.com/gardenbench" quote={"Rupa Eco Brick"} hastag={"#WebApp"}
                              >
                                <  EmailIcon size={37} round={true} />
                              </ EmailShareButton>
                            </div>
                            <div class="modal-footer">
                              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Link to="/benchpage" target="_blank" rel="noopener noreferrer">

                    <center><button type="button" className="btn btn-outline-dark mb-2"> <small> Click Here </small></button>
                    </center>
                    <center>
                      <h5 style={{ color: "Gray" }} className="card-title">Rupa Eco Brick</h5>
                    </center>
                  </Link>
                </div>

              </div>
            </div>




            <div className="col-md-6">

              <img src={Founder} className="img-fluid mb-4" alt="Founder" />
              <h3 style={{ color: "Gray" }} className="card-text"><b>SINCE -  2009</b></h3>
              <div className="colors1">
                <h5 className="card-text"><b>Rupa Eco Brick was founded/established in 2009, and we started full production on 28th February 2011. Since then we have been providing customer service. Then only fly ash bricks were made. After that many other products are being manufactured, including paver blocks, designer tiles and other concrete products. More new products will be presented to you in the
                  future. Your best wishes are very much desired. Hope you will be with us in the future as in the past.
                </b></h5></div>
            </div>

          </div>
        </div>
      </div>
      <div className="album py-2">
        <div className="container">

          <div className="row">

            <div className="col-md-6" >
              <div className="card" >
                <a href='block'>
                  <img src={Blockpage} className="card-img-top" alt="Blockpage" />
                </a>
                <div className="card-body">
                  <h5 className="card-title"><center>Block</center></h5>
                  <center>
                    <a href="block" class="btn btn-outline-dark ">Learn More</a>
                  </center>
                </div>
              </div>
            </div>
            <div className="col-md-6" >
              <div className="card" >
                <a href='Others'>
                  <img src={otherpage} className="card-img-top" alt="brickberhampore" />
                </a>
                <div className="card-body">
                  <h5 className="card-title mb-3"><center>Other</center></h5>
                  <center>
                    <a href="Others" class="btn btn-outline-dark ">Learn More</a>
                  </center>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      <hr />
      <div className="album py-1">
        <div className="container">
          <h1 style={{ color: "darkBlue" }}><b> Paver Block</b></h1>
        </div>
      </div>
      <Paverall />
      <div className="album py-1">
        <div className="container">
          <h1 style={{ color: "darkBlue" }}><b> Tiles</b></h1>
        </div>
      </div>
      <Tilespage />
      <div className="tilesberhampore">
        <center>
          <h1><b>RUPA ECO BRICK</b></h1>
          <h5>Rupa Eco Brick was founded/established in 2009</h5>
        </center>
      </div>

      <div className="album py-2">

        <div className="container">

          <div className="row">
            <h3 style={{ color: "Gray" }}> <b>Wall Tiles</b>- RUPA ECO BRICK </h3>
          </div>
        </div>
      </div>
      <div className="album py-1">

        <div className="container">

          <div className="row">

            <Carousel responsive={responsive}>
              <div className="card border mb-3 me-3" >
                <img className="card-img-top" src={Wallrupaeco} alt="product" />
                <div className="card-body text">
                  <h6 className="card-title mb-0"><b>Wall Tiles</b></h6>
                  <p className="card-text"><small>by Rupa Eco Brick</small></p>
                  <p className="card-text mb-0"><small>M -35 9"X3"X15MM </small></p>
                  <p style={{ color: "Gray" }} className="card-text "><small>Mankara, Balarampur, Berhampore</small></p>
                  <h6 className="card-title mb-0"><b> ₹10.00</b></h6>
                </div>
              </div>
              <div className="card border mb-3 me-3" >
                <img className="card-img-top" src={Walltile} alt="product" />
                <div className="card-body text">
                  <h6 className="card-title mb-0"><b>Wall Tiles</b></h6>
                  <p className="card-text"><small>by Rupa Eco Brick</small></p>
                  <p className="card-text mb-0"><small>M -35 9"X3"X15MM </small></p>
                  <p style={{ color: "Gray" }} className="card-text "><small>Mankara, Balarampur, Berhampore</small></p>
                  <h6 className="card-title mb-0"><b> ₹10.00</b></h6>
                </div>
              </div>
              <div className="card border mb-3 me-3" >
                <img className="card-img-top" src={Walltilesrupa} alt="product" />
                <div className="card-body text">
                  <h6 className="card-title mb-0"><b>Wall Tiles</b></h6>
                  <p className="card-text"><small>by Rupa Eco Brick</small></p>
                  <p className="card-text mb-0"><small>M -35 9"X3"X15MM </small></p>
                  <p style={{ color: "Gray" }} className="card-text "><small>Mankara, Balarampur, Berhampore</small></p>
                  <h6 className="card-title mb-0"><b> ₹10.00</b></h6>
                </div>
              </div>
              <div className="card border mb-3 me-3" >
                <img className="card-img-top" src={Walltiles} alt="Build" />
                <div className="card-body text">
                  <h6 className="card-title mb-0"><b>Wall Tiles</b></h6>
                  <p className="card-text"><small>by Rupa Eco Brick</small></p>
                  <p className="card-text mb-0"><small>M -35 9"X3"X15MM </small></p>
                  <p style={{ color: "Gray" }} className="card-text "><small>Mankara, Balarampur, Berhampore</small></p>
                  <h6 className="card-title mb-0"><b> ₹10.00</b></h6>
                </div>
              </div>
              <div className="card border mb-3 me-3" >
                <img className="card-img-top" src={Wallecobrick} alt="product" />
                <div className="card-body text">
                  <h6 className="card-title mb-0"><b>Wall Tiles</b></h6>
                  <p className="card-text"><small>by Rupa Eco Brick</small></p>
                  <p className="card-text mb-0"><small>M -35 9"X3"X15MM </small></p>
                  <p style={{ color: "Gray" }} className="card-text "><small>Mankara, Balarampur, Berhampore</small></p>
                  <h6 className="card-title mb-0"><b> ₹10.00</b></h6>
                </div>
              </div>
            </Carousel>
          </div>
        </div>

      </div>
      <br />
      <div className="album py-2">

        <div className="container">

          <div className="row">
            <h3 style={{ color: "Gray" }}> <b>Breeze Block</b> - RUPA ECO BRICK </h3>
          </div>
        </div>
      </div>
      <div className="album py-1">

        <div className="container">

          <div className="row">

            <Carousel responsive={responsive}>
              <div className="card border mb-3 me-3" >
                <img className="card-img-top" src={Breezeblock} alt="product" />
                <div className="card-body text">
                  <h6 className="card-title mb-0"><b>Breeze Block</b></h6>
                  <p className="card-text"><small>by Rupa Eco Brick</small></p>
                  <p className="card-text mb-0"><small>60 MNM </small></p>
                  <p style={{ color: "Gray" }} className="card-text "><small>Mankara, Balarampur, Berhampore</small></p>
                  <h6 className="card-title mb-0"><b> ₹80.00</b></h6>
                </div>
              </div>
              <div className="card border mb-3 me-3" >
                <img className="card-img-top" src={Blockrupaeco} alt="product" />
                <div className="card-body text">
                  <h6 className="card-title mb-0"><b>Breeze Block</b></h6>
                  <p className="card-text"><small>by Rupa Eco Brick</small></p>
                  <p className="card-text mb-0"><small>60 MNM </small></p>
                  <p style={{ color: "Gray" }} className="card-text "><small>Mankara, Balarampur, Berhampore</small></p>
                  <h6 className="card-title mb-0"><b> ₹80.00</b></h6>
                </div>
              </div>
              <div className="card border mb-3 me-3" >
                <img className="card-img-top" src={Breezeblockrupa} alt="product" />
                <div className="card-body text">
                  <h6 className="card-title mb-0"><b>Breeze Block</b></h6>
                  <p className="card-text"><small>by Rupa Eco Brick</small></p>
                  <p className="card-text mb-0"><small>60 MNM </small></p>
                  <p style={{ color: "Gray" }} className="card-text "><small>Mankara, Balarampur, Berhampore</small></p>
                  <h6 className="card-title mb-0"><b> ₹80.00</b></h6>
                </div>
              </div>
              <div className="card border mb-3 me-3" >
                <img className="card-img-top" src={Blockrupaecobrick} alt="Build" />
                <div className="card-body text">
                  <h6 className="card-title mb-0"><b>Breeze Block</b></h6>
                  <p className="card-text"><small>by Rupa Eco Brick</small></p>
                  <p className="card-text mb-0"><small>60 MNM </small></p>
                  <p style={{ color: "Gray" }} className="card-text "><small>Mankara, Balarampur, Berhampore</small></p>
                  <h6 className="card-title mb-0"><b> ₹80.00</b></h6>
                </div>
              </div>
              <div className="card border mb-3 me-3" >
                <img className="card-img-top" src={Blockrupa2} alt="product" />
                <div className="card-body text">
                  <h6 className="card-title mb-0"><b>Breeze Block</b></h6>
                  <p className="card-text"><small>by Rupa Eco Brick</small></p>
                  <p className="card-text mb-0"><small>60 MNM </small></p>
                  <p style={{ color: "Gray" }} className="card-text "><small>Mankara, Balarampur, Berhampore</small></p>
                  <h6 className="card-title mb-0"><b> ₹80.00</b></h6>
                </div>
              </div>
              <div className="card border mb-3 me-3" >
                <img className="card-img-top" src={Blockrupaeco1} alt="Build" />
                <div className="card-body text">
                  <h6 className="card-title mb-0"><b>Breeze Block</b></h6>
                  <p className="card-text"><small>by Rupa Eco Brick</small></p>
                  <p className="card-text mb-0"><small>60 MNM </small></p>
                  <p style={{ color: "Gray" }} className="card-text "><small>Mankara, Balarampur, Berhampore</small></p>
                  <h6 className="card-title mb-0"><b> ₹80.00</b></h6>
                </div>
              </div>
            </Carousel>
          </div>
        </div>

      </div>
      <br />
      <div className="album py-2">

        <div className="container">

          <div className="row">
            <h3 style={{ color: "Gray" }}> <b>Kerbe Stone</b> - RUPA ECO BRICK </h3>
          </div>
        </div>
      </div>
      <div className="album py-1">

        <div className="container">

          <div className="row">

            <Carousel responsive={responsive}>
              <div className="card border mb-3 me-3" >
                <img className="card-img-top" src={KERBEWSTONErUPAECOBRICK} alt="KERBE" />
                <div className="card-body text">
                  <h6 className="card-title mb-0"><b>Kerbe Stone </b></h6>
                  <p className="card-text"><small>by Rupa Eco Brick</small></p>
                  <p className="card-text mb-0"><small>M-35 450X300X1500 MM</small></p>
                  <p style={{ color: "Gray" }} className="card-text "><small>Mankara, Balarampur, Berhampore</small></p>
                  <h6 className="card-title mb-0"><b> ₹130.00</b></h6>
                </div>
              </div>
              <div className="card border mb-3 me-3" >
                <img className="card-img-top" src={RupaEcoBrick} alt="Kerbe_Stone" />
                <div className="card-body text">
                  <h6 className="card-title mb-0"><b>Kerbe Stone</b></h6>
                  <p className="card-text"><small>by Rupa Eco Brick</small></p>
                  <p className="card-text mb-0"><small>M-35 450X300X1500 MM </small></p>
                  <p style={{ color: "Gray" }} className="card-text "><small>Mankara, Balarampur, Berhampore</small></p>
                  <h6 className="card-title mb-0"><b> ₹130.00</b></h6>
                </div>
              </div>
              <div className="card border mb-3 me-3" >
                <img className="card-img-top" src={KERBEstone} alt="KERBEstone" />
                <div className="card-body text">
                  <h6 className="card-title mb-0"><b>Kerbe Stone</b></h6>
                  <p className="card-text"><small>by Rupa Eco Brick</small></p>
                  <p className="card-text mb-0"><small>M-35 450X300X1500 MM </small></p>
                  <p style={{ color: "Gray" }} className="card-text "><small>Mankara, Balarampur, Berhampore</small></p>
                  <h6 className="card-title mb-0"><b> ₹130.00</b></h6>
                </div>
              </div>
              <div className="card border mb-3 me-3" >
                <img className="card-img-top" src={KERBEStone} alt="KERBEStone" />
                <div className="card-body text">
                  <h6 className="card-title mb-0"><b>Kerbe Stone</b></h6>
                  <p className="card-text"><small>by Rupa Eco Brick</small></p>
                  <p className="card-text mb-0"><small>M-35 450X300X1500 MM</small></p>
                  <p style={{ color: "Gray" }} className="card-text "><small>Mankara, Balarampur, Berhampore</small></p>
                  <h6 className="card-title mb-0"><b> ₹130.00</b></h6>
                </div>
              </div>


            </Carousel>
          </div>
        </div>

      </div>


      <div className="brcikmurshidabad">
        <div className="album py-0">

          <div className="container">

            <div className="row">

            
              <div className="col-md-4" >
                <br />
                <div className="card mb-4 me-0 border-white box shadow">
                  <ReactPlayer width='100%' height="100%" url="https://www.youtube.com/watch?v=TNjbZ_zV4rM" />

                </div>


              </div>
              <div className="col-md-4" >
                <br />
                <div className="card mb-4 me-0 border-white box shadow">
                  <ReactPlayer width='100%' height="100%" url="https://www.youtube.com/watch?v=tzxu_Q84-_c" />

                </div>


              </div>

              {/* style={{textAlign: "center"}} */}







            </div>

          </div>
        </div>

      </div>
      <Slide />
      <Map />

    </div>
  )
}

export default Main
